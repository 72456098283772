@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$theme-colors: (
    "gosi": #ec118f,
    "gosi-blue-grey": #303c54,
    "gosi-blue": #2e4d80,
    "gosi-green": #336c5e,
    "gosi-red": #8e2940
);

$enable-gradients: true;

$gosi-gradient: (
    "start":  #ec118f,
    "stop":   #cd0f7c
);

$theme-gradients: (
    "gosi":    $gosi-gradient,
);

@each $color, $value in $theme-colors {
    .border-dashed-#{$color} { border: 1px dashed #{$value} }
}

.bg-gradient-gosi.gradient-y {
   background-image: linear-gradient(to bottom, #ec118f 0%, #cd0f7c 100%) !important;
   background-repeat: repeat-x !important;
}

$font-family-sans-serif: 'Roboto', sans-serif !default;

.gosi-bg { background: url('/assets/images/loginbg.jpg') no-repeat; background-size: cover; }
.gosi-bg-darken { background: rgba(0,0,0,0.5); }

.gosi-card-link { position: absolute; right: 0; bottom: 0; }
.gosi-card-icon { position: absolute; left: 0; bottom: 0; }

@media only screen and (max-width: 768px) {
    .gosi-card-icon { position: relative; float: right; }
}

$nav-tabs-link-active-color: #ec118f;
